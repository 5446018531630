import React from "react"
import classnames from 'classnames/bind'

// Components
import Layout from '@/components/common/Layout'

// Lib MISC
import '@/styles/main.scss'
import styles from '@/styles/static.scss'

const cx = classnames.bind(styles)

export default function TermsOfService ({ lang = 'en' }) {
  return <Layout lang={lang}>
    <div className={cx('static-banner')}>
      <p className={cx('static-banner-title')}>Terms of Service</p>
    </div>
    <div className={cx('static-content')}>
      <p>Thank you for using re.cards! These terms of service (Terms) cover your use and access of our website and related software (our Services).</p>
      <br />
      <p>These Services are provided by w69b GmbH, Schwabstr. 41, 72108 Rottenburg, Germany (we, our, or us).</p>
      <br />
      <p>By using our Services you agree to these Terms and to review our Privacy Policy. If you do not agree to these Terms or our Privacy Policy, you should not use our Services.</p>
      <ol>
        <br />
        <li>Software
          <p>Our Services may include web-based or downloadable software, which may update automatically on your device to newer versions. We grant you a worldwide, non-exclusive and non-transferable to use the software solely to use the Services. Components of the software may be offered under an open source license, in this case we will make that license available to you. Provisions of the open source license may expressly override some of these Terms.</p>
        </li>
        <br />
        <li>Changes to Services
          <p>We may change the features of our Services, withdraw or add new features from time to time.</p>
        </li>
        <br />
        <li>Pricing
          <p>The re.cards digital name card generation software on <a href={`https://re.cards/${lang}`}>{`https://re.cards/${lang}`}</a> is free for use.</p>
        </li>
        <br />
        <li>Limitation of Liability
          <br />
          <p>To the fullest extend permitted by law, in no event shall we be liable for any damages or loss of data, business, profits, computer hardware or software. In no event shall our liability to you exceed the greater than 10 USD or the amounts paid by you to us for the past 12 months.</p>
        </li>
        <br />
        <li>Warranties
          <br />
          <p>We strive to provide good Services and hope you enjoy using them. But there are things we cannot guarantee. We provide our Services "As is". To the fullest extend permitted by law, we make no warranties either implied or expressed about the Services. We do not make any promise about the specific functions of the services, their reliability or availability.</p>
        </li>
        <br />
        <li>Changes to Terms
          <br />
          <p>We reserve the right to change these Terms at any time. We will always post the most current version on our website. By continuing to use the Services after the changes become effective, you agree to the revised Terms.</p>
        </li>
        <br />
        <li>Data Processing Agreement, privacy policy and Confidentiality
          <br />
          <p>ReCards has taken the necessary technical and organizational security measures to prevent information saved by the Service from being accidentally or illegally destroyed, lost or wasted and to prevent such information from falling into the hands of any unauthorized party, being misused or otherwise treated in a way contrary ReCards’s privacy policy (see: <a href={`https://re.cards/${lang}/PrivacyPolicy.html`}>{`https://re.cards/${lang}/PrivacyPolicy.html`}</a>).</p>
          <br />
          <p>ReCards is bound by secrecy in respect of any information received about the Customer and will not disclose such information to any third party except where it is required to do so by any court or regulatory authority and then only to the extent necessary.</p>
          <br />
        </li>
        <br />
        <li>Marketing
          <br />
          <p>ReCards shall be entitled to refer to the Customer and the Services provided in its marketing, including a brief description of such services.</p>
        </li>
        <br />
        <li>Disputes
          <br />
          <p>These Terms shall be governed by and construed in accordance with the laws of Hong Kong and the Hong Kong Courts shall have exclusive jurisdiction to determine any dispute concerning these Terms or the subject matter of these Terms.</p>
        </li>
      </ol>
      Revised: Nov 16, 2021.
    </div >
  </Layout>
}